import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminDetails: null,
};

export const adminDetailSlice = createSlice({
  name: "adminDetailMarkUp",
  initialState,
  reducers: {
    setAdminDetails(state, action) {
      state.adminDetails = action.payload;
    },
  },
});

export const { setAdminDetails } = adminDetailSlice.actions;
export default adminDetailSlice.reducer;
