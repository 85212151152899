import { ResponsiveBar } from "@nivo/bar";
import React from "react";
import useMediaQuery from "../common/mediaQuery/MediaQueryHook";

const AdminChildChart = ({ data }) => {
  const customColors = {
    BCG: "#7161EF",
    DPT_HepB_HIB: "#EF6161",
    IPV: "",
    Malaria: "",
    Measles_Rubella: "",
    PCV: "",
    Polio: "",
    Rota: "",
    Yellow_Fever: "",
  };

  const isLargeScreen = useMediaQuery("(min-width: 768px)");
  if (Array.isArray(data)) {
    return (
      <div style={{ height: "450px" }}>
        <ResponsiveBar
          data={data}
          keys={[
            "BCG",
            "DPT_HepB_HIB",
            "IPV",
            "Malaria",
            "Measles_Rubella",
            "PCV",
            "Polio",
            "Rota",
            "Yellow_Fever",
          ]}
          indexBy="x"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          layout={isLargeScreen ? "vertical" : "horizontal"}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "pastel2" }}
          //   colors={(bar) => customColors[bar.id]}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#38bcb2",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "#eed312",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -70,
            legendOffset: 32,
            style: {
              fontSize: 9,
            },
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="#000"
          legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
          role="application"
        />
      </div>
    );
  }
};

export default AdminChildChart;
