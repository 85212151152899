import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "../../pages/Dashboard";
import UserManagement from "../../pages/UserManagement";
import UserRequest from "../../pages/UserRequest";
import ViewUser from "../../pages/ViewUser";
import "../../scss/common/layout.scss";
import Sidebar from "../form/Sidebar";
import AppHeader from "./AppHeader";

const Layout = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let accessToken = sessionStorage.getItem("access_token");

  useEffect(() => {
    if (pathname === "/") {
      navigate("/login");
    }
    if (pathname) {
      setMenuOpen(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, [accessToken]);

  const handleMenuStatus = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="layout flex">
      <div
        className={`sidebar-wrapper min-h-screen lg:sticky top-0 left-0 min-w-[270px] max-h-screen z-[99] overflow-y-auto ${
          menuOpen && "open-menu"
        }`}
      >
        <Sidebar />
      </div>
      <div
        className={`bg-[#FAFAFA] flex-1 lg:max-w-[calc(100%-270px)] w-full `}
      >
        <div>
          <AppHeader mobileMenuHandler={handleMenuStatus} menuOpen={menuOpen} />
        </div>
        <div
          className="body-content h-full lg:max-h-[calc(100dvh-80px)] max-h-[calc(100dvh-72px)] lg:min-h-[calc(100dvh-80px)] min-h-[calc(100dvh-72px)] overflow-auto "
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/user-management" element={<UserManagement />} />
            <Route path="/user-request" element={<UserRequest />} />
            <Route path="/view-user/:id" element={<ViewUser />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Layout;
