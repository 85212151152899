import React from "react";

const Button = (props) => {
  const {
    name,
    type,
    classname,
    width,
    attribute,
    clickHandler,
    btnType,
    children,
    isLoading,
  } = props;

  const handleClick = () => {
    if (!isLoading && clickHandler) {
      clickHandler();
    }
  };
  return (
    <button
      type={type}
      name={name}
      className={`relative button flex lg:gap-4 gap-2 justify-center items-center lg:h-[40px] h-[40px] ${
        btnType === "normal" && "lg:text-base text-sm"
      } text-white font-medium lg:py-3 lg:px-5 py-2 px-4 w-full bg-primary border border-primary shadow-buttonShadow rounded-md capitalize transition-all duration-300 hover:bg-white hover:text-primary ${classname}`}
      style={{ maxWidth: width }}
      onClick={handleClick}
      disabled={isLoading}
      {...attribute}
    >
      {isLoading && <span className="btn-loader"></span>}
      <span className={`${isLoading && "opacity-0"} flex gap-1.5 items-center`}>
        {children}
      </span>
    </button>
  );
};

export default Button;
