import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../utils/helper";

// let API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
let API_BASE_URL = BASE_URL;

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}`,
    prepareHeaders: (headers, { getState }) => {
      let token = sessionStorage.getItem("access_token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `/admin/login`,
        method: "POST",
        body: data,
      }),
    }),
    getDashboardChildCounts: builder.mutation({
      query: (data) => ({
        url: `/admin/dashboard/childrens/vaccine-counts`,
        method: "POST",
        body: data,
      }),
    }),
    getChildDashboardChildChart: builder.mutation({
      query: (data) => ({
        url: `/admin/dashboard/childrens/vaccine-graph`,
        method: "POST",
        body: data,
      }),
    }),
    getUserManagementList: builder.mutation({
      query: (param) => ({
        url: `/admin/users/list`,
        params: param,
        method: "GET",
      }),
    }),
    getSingleUserDetail: builder.mutation({
      query: (userId) => ({
        url: `/admin/users/${userId}`,
        method: "GET",
      }),
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `/admin/users/${userId}`,
        method: "DELETE",
      }),
    }),
    updateUser: builder.mutation({
      query: ({ query, data }) => ({
        url: `/admin/users/${query.userId}`,
        method: "PUT",
        body: data,
      }),
    }),
    getUserRequestList: builder.mutation({
      query: (param) => ({
        url: `/admin/users/approval-list`,
        params: param,
        method: "GET",
      }),
    }),
    approveUser: builder.mutation({
      query: ({ query, data }) => ({
        url: `/admin/users/${query.userId}`,
        method: "PATCH",
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useGetDashboardChildCountsMutation,
  useGetChildDashboardChildChartMutation,
  useGetUserManagementListMutation,
  useGetSingleUserDetailMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetUserRequestListMutation,
  useApproveUserMutation,
} = authApi;
