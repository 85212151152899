import React from "react";

const CountCard = ({data}) => {
  return (
    <div className="card">
      <p className="md:text-base text-sm text-[#000000B2] font-medium">
        {(data?.vaccine).replaceAll('_',' ')}
      </p>
      <p className="pt-3 xl:text-2xl lg:text-lg md:text-base text-sm text-font font-bold mt-0">
        {data?.dosesCompleted ?? 0}
      </p>
    </div>
  );
};

export default CountCard;
