import React from "react";
import { AcceptIcon, RejectIcon } from "../../assets/Icons";
import TableLoader from "../common/loader/TableLoader";
import TableNoDataFound from "../common/loader/TableNoDataFound";

const UserRequestTable = ({ lists, loading, handleAction }) => {
  return (
    <div className="defaulter-table">
      <table className="">
        <thead>
          <tr>
            <td>#</td>
            <td>Name</td>
            <td>Username</td>
            <td>Role</td>
            <td>Role Type</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <TableLoader />
          ) : lists?.length > 0 ? (
            lists.map((list, index) => {
              return (
                <tr key={list?.id}>
                  <td>{index + 1}</td>
                  <td>
                    <span className="flex gap-3 items-center">
                      <span className="w-[34px] h-[34px] rounded-full bg-gray-500"></span>
                      <span className="capitalize">
                        {(list?.firstName && list?.lastName) ? `${list?.firstName} ${list?.lastName}` : "---"}
                      </span>
                    </span>
                  </td>
                  <td>{list?.userName ? `@${list?.userName}` : "---"}</td>
                  <td className="capitalize">
                    {(list?.userType).replace("_", " ")}
                  </td>
                  <td className="uppercase">
                    {(list?.healthWorkerType).replace("_", " ")}
                  </td>

                  <td>
                    <span className="flex gap-3 items-center justify-end">
                      <span
                        className="text-color_01F17E whitespace-nowrap flex gap-1.5 items-center cursor-pointer"
                        onClick={() => handleAction(list?.id, "Approved")}
                      >
                        <span className="">
                          <AcceptIcon />
                        </span>
                        Approve
                      </span>
                      <span
                        className="text-danger whitespace-nowrap flex gap-1.5 items-center cursor-pointer"
                        onClick={() => handleAction(list?.id, "Rejected")}
                      >
                        <span className="">
                          <RejectIcon />
                        </span>
                        Reject
                      </span>
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <TableNoDataFound />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserRequestTable;
