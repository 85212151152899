import { debounce } from "lodash";
import { useRef, useState } from "react";
import { default as ReactSelect, components } from "react-select";

const defaultOptionLabel = ["label"];
const defaultOptionValue = "value";
const empty_function = () => {};
// value container change like in muliple select 1 selected.. 2 selected
const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? "" : "s";
    values = `${values.length} item${plural} selected`;
  }
  return (
    <components.ValueContainer {...props}>
      {values?.key === "placeholder" ? (
        values
      ) : (
        <span className="text-[#cdd2d4] text-sm mr-1">{values}</span>
      )}
      {input}
    </components.ValueContainer>
  );
};

var changeOptionLabel = (option, labels) => {
  if (labels.length > 1) {
    let fullLabel = "";
    for (const label of labels) {
      fullLabel += option[label] + "-";
    }
    return fullLabel;
  } else {
    return option[labels[0]];
  }
};
var changeOptionValue = (option, value) => {
  return option[value];
};

const DropDown = ({
  isMultiSelected = false,
  defaultValue,
  isSearchable = true,
  isLoading = false,
  isClearable = true,
  options = [],
  selectedValue = null, // selected value
  menuPlacement = "auto",
  onSelectChange = empty_function,
  onChangeSearch = empty_function,
  inputWidth,
  placeholder = "Select",
  customOptionLabel = defaultOptionLabel,
  customOptionValue = defaultOptionValue,
  disabled = false,
}) => {
  let CustomComponents = { ...components };
  const [innerSearch, setInnerSearch] = useState("");
  const delayedQuery = debounce((q) => onChangeSearch(q), 500);
  let isAllSelected = false;
  const selectAllLabel = useRef("Select all");
  const allOption = { value: "*", label: selectAllLabel.current };
  const changeAllSelected = () => {
    isAllSelected = !isAllSelected;
  };

  const Option = (props) => (
    <components.Option {...props}>
      {props.value === "*" ? (
        <input
          type="checkbox"
          className={`checkbox-input ${
            props.options?.length > 0
              ? ""
              : "opacity-50 pointer-events-none cursor-not-allowed"
          }`}
          name="selectAll"
          id="allselected"
          onChange={changeAllSelected}
          checked={isAllSelected}
        />
      ) : (
        <input
          key={props.value}
          type="checkbox"
          checked={props.isSelected || isAllSelected}
          onChange={() => {}}
        />
      )}
      <label style={{ marginLeft: "5px" }}>{props.label}</label>
    </components.Option>
  );
  const onInputChange = (inputValue, event) => {
    if (event.action === "input-change") {
      setInnerSearch(inputValue);
      delayedQuery(inputValue);
    } else if (event.action === "menu-close" && innerSearch !== "") {
      setInnerSearch("");
      delayedQuery("");
    } // change outer search
  };

  const filterSelectedOptions = (filterIn, filterFrom) => {
    return filterIn.filter((mainOption) => {
      if (mainOption.value == "*") return false;
      for (const selectedOption of filterFrom) {
        if (selectedOption.value == "*") continue;
        if (mainOption[customOptionValue] == selectedOption[customOptionValue])
          return false;
      }
      return true;
    });
  };

  const handleChange = (selected, { action, option }) => {
    if (
      isMultiSelected &&
      action == "select-option" &&
      !isAllSelected &&
      option.value == "*"
    ) {
      isAllSelected = true;
      onSelectChange([
        ...selectedValue,
        ...filterSelectedOptions(options, selectedValue),
      ]);
    } else if (
      isMultiSelected &&
      action == "select-option" &&
      isAllSelected &&
      option.value == "*"
    ) {
      isAllSelected = false;
      onSelectChange(filterSelectedOptions(selectedValue, options));
    } else {
      onSelectChange(selected);
    }
  };

  const isCheckedFunc = (selectedOptions) => {
    if (!selectedOptions || selectedOptions.length == 0) return -1;
    let selectedCount = 0;
    for (const option of options) {
      selectedOptions.findIndex((selectedOption) => {
        return selectedOption[customOptionValue] == option[customOptionValue];
      });
      if (
        selectedOptions.findIndex(
          (selectedOption) =>
            selectedOption[customOptionValue] == option[customOptionValue]
        ) >= 0
      ) {
        selectedCount++;
      }
    }
    return selectedCount;
  };

  const customStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      position: "relative",
      width: inputWidth ? inputWidth : "200px",
      borderColor: "#7161EF",
      borderRadius: "6px",
      fontSize:'12px',

      "&:hover": {
        borderColor: "#7161EF"
      }
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "#263A43",
      fontSize: "12px",
    }),
    container: (def) => ({
      ...def,
      minWidth: { inputWidth },
    }),
    multiValueLabel: (def) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    multiValueRemove: (def) => ({
      ...def,
      backgroundColor: "lightgray",
    }),
    valueContainer: (base) => ({
      ...base,
      minHeight: "38px",
      maxHeight: "44px",
      overflow: "auto",
    }),
    indicatorSeparator: () => ({ display: "none" }),
    option: (styles, { isSelected, isFocused }) => {
      return {
        ...styles,
        backgroundColor:
          isSelected && !isFocused
            ? null
            : isFocused && !isSelected
            ? styles.backgroundColor
            : isFocused && isSelected
            ? "#DEEBFF"
            : null,
        color: isSelected ? "#7161EF" : "#263A43",
        fontSize: "12px",
      };
    },
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (def) => ({
      ...def,
      zIndex: 999999,
      top: "auto",
      width: inputWidth ? inputWidth : "200px",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#263A43",
      paddingLeft:'3px',
    }),
  };

  if (isMultiSelected && options?.length !== 0) {
    let currentSelectedCount = isCheckedFunc(selectedValue);
    isAllSelected = currentSelectedCount >= options.length;
    if (isAllSelected) {
      selectAllLabel.current = `All (${options.length}) selected`;
    }
    if (currentSelectedCount > 0) {
      selectAllLabel.current = `${currentSelectedCount} / ${options.length} selected`;
    } else selectAllLabel.current = "Select all";

    allOption.label = selectAllLabel.current;
    options = [allOption, ...options];
  }
  if (isMultiSelected)
    CustomComponents = { ...components, ValueContainer, Option };

  return (
    <ReactSelect
      classNamePrefix="scrollbar scrollbar-w-1 scrollbar-h-1 scrollbar-thumb-color_8391A2 "
      value={selectedValue}
      defaultValue={defaultValue}
      isMulti={isMultiSelected}
      isSearchable={isSearchable}
      // isLoading={isLoading}
      inputValue={innerSearch}
      onInputChange={onInputChange}
      options={options}
      onChange={handleChange}
      components={CustomComponents}
      // menuPosition={"fixed"}
      menuPlacement={menuPlacement ?? "auto"}
      styles={customStyles}
      placeholder={placeholder}
      closeMenuOnSelect={!isMultiSelected}
      tabSelectsValue={false}
      isClearable={isClearable}
      backspaceRemovesValue={false}
      hideSelectedOptions={false}
      blurInputOnSelect={false}
      filterOption={null}
      isDisabled={disabled}
      getOptionLabel={(option) =>
        option.value != "*"
          ? changeOptionLabel(option, customOptionLabel)
          : allOption.label
      }
      getOptionValue={(option) =>
        option.value != "*"
          ? changeOptionValue(option, customOptionValue)
          : allOption.value
      }
    />
  );
};

export default DropDown;
