import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Login from "../../pages/account/Login";

const Routing = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      {/*<Route path="register" element={<Register />} /> */}
      <Route path="/*" element={<Layout />} />
    </Routes>
  );
};

export default Routing;
