import { Pagination, ThemeProvider, createTheme } from "@mui/material";
import React from "react";
import { NextArrowIcon, PrevArrowIcon } from "../../../assets/Icons";

const theme = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          width: "26px",
          minWidth: "26px",
          height: "26px",
          color: "#CCCCCC",
          "&.Mui-selected": {
            backgroundColor: "#7161EF",
            color: "white",
          },
        },
        icon: {
          color: "#7161EF", // Change the color of the "next" arrow
        },
      },
    },
  },
});

const CustomPagination = (props) => {
  const { setPage, count, page } = props;

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="flex mt-8 justify-center">
      <ThemeProvider theme={theme}>
        <Pagination
          count={Math.ceil(count / 10)}
          page={page}
          onChange={handleChange}
          nexticon={<NextArrowIcon />}
          previcon={<PrevArrowIcon />}
        />
      </ThemeProvider>
    </div>
  );
};

export default CustomPagination;
