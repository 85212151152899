import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import React, { useState } from "react";
import dayjs from "dayjs";

const newTheme = (theme) =>
  createTheme({
    ...theme,
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            color: "#263a43",
            borderRadius: 20,
            backgroundColor: "#fff",
            padding: 0,
          },
        },
      },
    },
  });

const DatePickerApp = (props) => {
  const {
    type,
    attribute,
    className,
    label,
    placeholder,
    name,
    changeEvent,
    dateFormat = "dd/MM/yyyy",
    isFocus = false,
    isErrorMsg = null,
    isReadOnly = false,
    isDisabled = false,
    value,
  } = props;
  const [startDate, setStartDate] = useState(new Date());

  const handleDate = (date) => {
    setStartDate(date);
    changeEvent(name, date);
  };
  return (
    <div
      className={`datePicker flex gap-2.5 flex-col ${
        isDisabled ? "cursor-not-allowed" : ""
      }`}
    >
      <label className="input-label">{label}</label>
      {type == "datePicker" && (
        // <DatePicker
        //   showIcon
        //   icon={
        //     <svg
        //       width="23"
        //       height="24"
        //       viewBox="0 0 23 24"
        //       fill="none"
        //       xmlns="http://www.w3.org/2000/svg"
        //     >
        //       <path
        //         d="M11.5 14.4c-.346 0-.637-.115-.871-.346a1.146 1.146 0 0 1-.351-.854c0-.34.117-.625.352-.856A1.19 1.19 0 0 1 11.5 12c.346 0 .637.115.871.346.235.23.352.515.351.854 0 .34-.117.625-.352.856a1.19 1.19 0 0 1-.87.344Zm-4.889 0c-.346 0-.637-.115-.871-.346a1.146 1.146 0 0 1-.351-.854c0-.34.117-.625.352-.856A1.19 1.19 0 0 1 6.61 12c.346 0 .637.115.872.346.234.23.351.515.35.854 0 .34-.117.625-.352.856a1.19 1.19 0 0 1-.87.344Zm9.778 0c-.346 0-.637-.115-.872-.346a1.146 1.146 0 0 1-.35-.854c0-.34.117-.625.352-.856a1.19 1.19 0 0 1 .87-.344c.346 0 .637.115.871.346.235.23.352.515.351.854 0 .34-.117.625-.352.856a1.19 1.19 0 0 1-.87.344ZM11.5 19.2c-.346 0-.637-.115-.871-.346a1.146 1.146 0 0 1-.351-.854c0-.34.117-.625.352-.856a1.19 1.19 0 0 1 .87-.344c.346 0 .637.115.871.346.235.23.352.515.351.854 0 .34-.117.625-.352.856a1.19 1.19 0 0 1-.87.344Zm-4.889 0c-.346 0-.637-.115-.871-.346A1.146 1.146 0 0 1 5.389 18c0-.34.117-.625.352-.856a1.19 1.19 0 0 1 .87-.344c.346 0 .637.115.872.346.234.23.351.515.35.854 0 .34-.117.625-.352.856a1.19 1.19 0 0 1-.87.344Zm9.778 0c-.346 0-.637-.115-.872-.346a1.146 1.146 0 0 1-.35-.854c0-.34.117-.625.352-.856a1.19 1.19 0 0 1 .87-.344c.346 0 .637.115.871.346.235.23.352.515.351.854 0 .34-.117.625-.352.856a1.19 1.19 0 0 1-.87.344ZM2.944 24a2.377 2.377 0 0 1-1.727-.706A2.286 2.286 0 0 1 .5 21.6V4.8c0-.66.24-1.225.719-1.696A2.371 2.371 0 0 1 2.944 2.4h1.223V0H6.61v2.4h9.778V0h2.444v2.4h1.223c.672 0 1.247.235 1.727.706.479.47.718 1.035.717 1.694v16.8c0 .66-.24 1.225-.719 1.696a2.37 2.37 0 0 1-1.725.704H2.944Zm0-2.4h17.112v-12H2.944v12Z"
        //         fill="#758092"
        //       />
        //     </svg>
        //   }
        //   selected={value || startDate}
        //   onChange={(date) => handleDate(date)}
        //   name={name}
        //   dateFormat={dateFormat}
        //   placeholderText={placeholder}
        //   disabled={isDisabled}
        //   className={`${isDisabled ? "!bg-[#e6e3e3] !cursor-not-allowed" : ''}`}
        //   {...attribute}
        // />
        <ThemeProvider theme={(newTheme) => newTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                defaultValue={dayjs(new Date())}
                value={value}
                onChange={(newValue) => handleDate(newValue)}
                name={name}
                // label={placeholder}
                disableFuture
                disabled={isDisabled}
                className={`${
                  isDisabled ? "!bg-[#e6e3e3] !cursor-not-allowed" : ""
                }`}
                {...attribute}
              />
            </DemoContainer>
          </LocalizationProvider>
        </ThemeProvider>
      )}
    </div>
  );
};

export default DatePickerApp;
