import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGetChildDashboardChildChartMutation,
  useGetDashboardChildCountsMutation,
} from "../apis/authApi";
import CustomDateRange from "../components/common/CustomDateRange";
import CountCard from "../components/dashboard/CountCard";
import "../scss/dashboardTabs.scss";
import { addNotification } from "../store/slices/toaster-slice";
import { calculateDateDiff } from "../utils/helper";
import AdminChildChart from "../components/dashboard/AdminChildChart";

const today = new Date();
const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

const Dashboard = () => {
  const [childCountsData, setChildCountsData] = useState({});
  const [dashboardChildChartData, setDashboardChildChartData] = useState({});
  const [dateRange, setDateRange] = useState({
    from: firstDayOfMonth,
    to: lastDayOfMonth,
  });
  const [dashboardChildCount, dashboardChildCountLoading] =
    useGetDashboardChildCountsMutation();
  const [dashboardChildChart, dashboardChildChartLoading] =
    useGetChildDashboardChildChartMutation();
  const [value, setValue] = React.useState("1");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getDashboardChildCounts();
    getDashboardChildChartData();
  }, [dateRange]);

  const getDashboardChildCounts = async () => {
    try {
      let data = {
        from: dateRange?.from || firstDayOfMonth,
        to: dateRange?.to || lastDayOfMonth,
      };
      let response = await dashboardChildCount(data).unwrap();
      setChildCountsData(response?.result);
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };

  const setType = (data) => {
    let type = "date";

    const diff = calculateDateDiff(data.from, data.to);
    if (diff === 0) {
      type = "hour";
    } else if (diff < 7) {
      type = "day";
    } else if (diff < 31) {
      type = "date";
    } else {
      type = "month";
    }

    return type;
  };

  const getDashboardChildChartData = async () => {
    try {
      let type = setType({
        from: dateRange?.from || firstDayOfMonth,
        to: dateRange?.to || lastDayOfMonth,
      });
      let data = {
        from: dateRange?.from || firstDayOfMonth,
        to: dateRange?.to || lastDayOfMonth,
        type: type,
      };
      let response = await dashboardChildChart(data).unwrap();
      setDashboardChildChartData(response?.result);
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4 rounded-xl">
      <div className="py-5 px-5 bg-primary_gradient rounded-lg">
        <h3 className="text-white font-bold uppercase">
          Welcome To Your Admin Portal!!
        </h3>
      </div>
      <div className="mt-5 bg-white p-4 rounded-xl">
        <TabContext value={value}>
          <Box
            sx={{ bgcolor: "", borderBottom: 1, borderColor: "divider" }}
          >
            <TabList onChange={handleTabChange}>
              <Tab
                label="Child"
                value="1"
                sx={{
                  "&.Mui-selected": { background: "#7161EF", color: "#fff"},
                }}
              />
              <Tab
                label="HPV"
                value="2"
                sx={{
                  "&.Mui-selected": { background: "#7161EF", color: "#fff"},
                }}
              />
              <Tab
                label="TD"
                value="3"
                sx={{
                  "&.Mui-selected": { background: "#7161EF", color: "#fff"},
                }}
              />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: "20px 15px" }}>
            <CustomDateRange setDateRange={setDateRange} />
            <div className="mt-4">
              <div className="flex-1 min-h-[100px] bg-white">
                {dashboardChildChartLoading.isLoading ? (
                  <div className="skeleton-box w-full min-h-[300px]" />
                ) : (
                  <AdminChildChart data={dashboardChildChartData} />
                )}
              </div>
              <div className="cards mt-8">
                {childCountsData?.length &&
                  childCountsData.map((data, index) => {
                    return <CountCard data={data} key={index} />;
                  })}
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">Not Available</TabPanel>
          <TabPanel value="3">Not Available</TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

export default Dashboard;
