import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  DataAnalyticsIcon,
  HomeIcon,
  RegisterRequestIcon,
  SettingIcon,
  UserManagementIcon,
} from "../../../assets/Icons";

const AdminMenu = () => {
  const { pathname } = useLocation();

  return (
    <>
      <ul className="menu">
        <li>
          <Link
            to="/dashboard"
            className={`menu-link ${pathname === "/dashboard" && "active"}`}
          >
            <span>
              <HomeIcon />
            </span>
            Dashboard
          </Link>
        </li>
        <li>
          <Link
            to="/user-management"
            className={`menu-link ${
              pathname === "/user-management" && "active"
            }`}
          >
            <span>
              <UserManagementIcon />
            </span>
            User Management
          </Link>
        </li>
        <li>
          <Link
            to="/user-request"
            className={`menu-link ${pathname === "/user-request" && "active"}`}
          >
            <span className="">
              <RegisterRequestIcon />
            </span>
            User Register Requests
          </Link>
        </li>
        {/* <li>
          <Link
            to="/analytics"
            className={`menu-link ${
              pathname.includes("/analytics") && "active"
            }`}
          >
            <span className="relative -top-1">
              <DataAnalyticsIcon />
            </span>
            Data Analytic
          </Link>
        </li>
        <li>
          <Link
            to="/setting"
            className={`menu-link ${pathname === "/setting" && "active"}`}
          >
            <span>
              <SettingIcon />
            </span>
            System Setting
          </Link>
        </li> */}
      </ul>
    </>
  );
};

export default AdminMenu;
