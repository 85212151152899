import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetUserManagementListMutation } from "../apis/authApi";
import SearchInput from "../components/common/searchInput/searchInput";
import UserManagementTable from "../components/userManagement/UserManagementTable";
import { addNotification } from "../store/slices/toaster-slice";
import "../scss/common/userManagement.scss";
import CustomPagination from "../components/common/pagination/CustomPagination";

const UsersManagement = () => {
  const [searchValue, setSearchValue] = useState("");
  const [getUsers, usersLoading] = useGetUserManagementListMutation();
  const [usersLists, setUsersList] = useState({});
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetUsers();
  }, [page, searchValue]);

  const handleGetUsers = async () => {
    try {
      const query = {
        search: searchValue,
        page: page,
      };
      let response = await getUsers(query).unwrap();
      setUsersList(response?.result);
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };
  return (
    <div className="xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4 rounded-xl">
      <div className="">
        <SearchInput setSearchValue={setSearchValue} placeholder="Search..." />
      </div>
      <div className="admin-table mt-8 bg-white rounded-xl overflow-auto ">
        <UserManagementTable
          lists={usersLists?.users}
          loading={usersLoading?.isLoading}
          refreshData={handleGetUsers}
        />
      </div>
      {usersLists?.page?.total > 10 && (
        <CustomPagination
          setPage={setPage}
          page={page}
          count={usersLists?.page?.total}
        />
      )}
    </div>
  );
};

export default UsersManagement;
