import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { TrashIcon, ViewIcon } from "../../assets/Icons";
import TableLoader from "../common/loader/TableLoader";
import TableNoDataFound from "../common/loader/TableNoDataFound";
import { useDeleteUserMutation } from "../../apis/authApi";
import { useDispatch } from "react-redux";
import { addNotification } from "../../store/slices/toaster-slice";

const UserManagementTable = ({ lists, loading, refreshData }) => {
  const [deleteUser, deleteUserLoading] = useDeleteUserMutation();
  const dispatch = useDispatch();

  const handleDeleteUser = async (userId) => {
    try {
      const response = await deleteUser(userId).unwrap();
      dispatch(
        addNotification({
          type: "success",
          message: "User Deleted Successfully",
        })
      );
      refreshData();
    } catch (error) {
      dispatch(
        addNotification({ type: "error", message: error?.data?.result })
      );
    }
  };

  return (
    <div className="">
      <table className="">
        <thead>
          <tr>
            <td>#</td>
            <td>Name</td>
            <td>Username</td>
            <td>Role</td>
            <td>Role Type</td>
            <td>Status</td>
            <td>Activity</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <TableLoader />
          ) : lists?.length > 0 ? (
            lists.map((list, index) => {
              return (
                <tr key={list?.id}>
                  <td>{index + 1}</td>
                  <td>
                    <span className="flex gap-3 items-center">
                      <span className="w-[34px] h-[34px] rounded-full bg-gray-500"></span>
                      <span className="capitalize">
                        {list?.fullname ?? "---"}
                      </span>
                    </span>
                  </td>
                  <td>{list?.userName ? `@${list?.userName}` : "---"}</td>
                  <td className="capitalize">
                    {(list?.userType).replace("_", " ")}
                  </td>
                  <td className="uppercase">{list?.healthWorkerType}</td>
                  <td>
                    {list?.isActive ? (
                      <span className="text-success">Active</span>
                    ) : (
                      <span className="text-danger">Inactive</span>
                    )}
                  </td>
                  <td>{list?.totalVaccinatedTillNow ?? 0}</td>
                  <td>
                    <span className="flex items-center gap-3">
                      <Link
                        className="icon-hover cursor-pointer"
                        to={`/view-user/${list?.id}`}
                      >
                        <ViewIcon />
                      </Link>
                      <span
                        className="icon-hover cursor-pointer"
                        onClick={() => handleDeleteUser(list?.id)}
                      >
                        <TrashIcon fill="#21272A" />
                      </span>
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <TableNoDataFound />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserManagementTable;
