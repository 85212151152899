import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useApproveUserMutation,
  useGetUserRequestListMutation,
} from "../apis/authApi";
import SearchInput from "../components/common/searchInput/searchInput";
import UserRequestTable from "../components/userManagement/UserRequestTable";
import "../scss/common/userManagement.scss";
import { addNotification } from "../store/slices/toaster-slice";

const UserRequest = () => {
  const [searchValue, setSearchValue] = useState("");
  const [getUserRequests, userRequestsLoading] =
    useGetUserRequestListMutation();
  const [approveRejectUser, approveRejectUserLoading] =
    useApproveUserMutation();
  const [userRequestsLists, setUserRequestsList] = useState({});
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetUserRequests();
  }, [page, searchValue]);

  const handleGetUserRequests = async () => {
    try {
      const query = {
        page: page,
      };
      let response = await getUserRequests(query).unwrap();
      setUserRequestsList(response?.result);
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };

  const handleAction = async (id, action) => {
    try {
      const query = {
        userId: id,
      };
      const data = {
        status: action,
      };
      const response = await approveRejectUser({ query, data }).unwrap();
      dispatch(addNotification({ type: "success", message: response?.result }));
      handleGetUserRequests();
    } catch (error) {}
  };
  return (
    <div className="xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4 rounded-xl">
      <div className="">
        <SearchInput setSearchValue={setSearchValue} placeholder="Search..." />
      </div>
      <div className="admin-table mt-8 bg-white rounded-xl overflow-auto ">
        <UserRequestTable
          lists={userRequestsLists?.users}
          loading={userRequestsLoading?.isLoading}
          handleAction={handleAction}
        />
      </div>
    </div>
  );
};

export default UserRequest;
