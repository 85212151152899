import React, { useEffect, useState } from "react";
import {
  useGetSingleUserDetailMutation,
  useUpdateUserMutation,
} from "../apis/authApi";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addNotification } from "../store/slices/toaster-slice";
import Loader from "../components/common/loader/Loader";
import Input from "../components/form/input/index";
import Button from "../components/common/Button";
import { EditIcon } from "../assets/Icons";
import { regEx } from "../utils/helper";
import DropDown from '../components/common/Dropdown';

const initialState = {
  userName: "",
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  title: "",
  userType: "health_worker",
  isActive: true,
};
const NameTitle = [
  {
    label: "Dr.",
    value: "Dr.",
  },
  { label: "Nurse", value: "nurse" },
  { label: "Midwife", value: "midwife" },
];

const validateRegForm = (data) => {
  let errors = {};
  let isValid = true;

  if (!data?.firstName) {
    errors.firstName = "First name is required";
    isValid = false;
  } else if (!regEx.NAME.test(data?.firstName)) {
    errors.firstName = "Invalid first name";
    isValid = false;
  }
  if (!data?.lastName) {
    errors.lastName = "Last name is required";
    isValid = false;
  } else if (!regEx.NAME.test(data?.lastName)) {
    errors.lastName = "Invalid last name";
    isValid = false;
  }
  if (!data?.contact) {
    errors.contact = "Phone no. is required";
    isValid = false;
  } else if (!regEx.PHONE.test(data?.contact)) {
    errors.contact = "Invalid phone no.";
    isValid = false;
  }
  if (!data?.userName) {
    errors.userName = "username is required";
    isValid = false;
  }

  return { isValid, errors };
};

const ViewUser = () => {
  const [getUserDetail, userDetailLoading] = useGetSingleUserDetailMutation();
  const [updateUser, updateUserLoading] = useUpdateUserMutation();
  const [formData, setFormData] = useState(initialState);
  const [editMode, setEditMode] = useState(false);
  const [formError, setFormError] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleGetUser(id);
  }, [id]);

  const handleGetUser = async (id) => {
    try {
      let response = await getUserDetail(id).unwrap();
      setFormData(response?.result?.user);
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };

  const handleTitleChange = (name, value) => {
    setFormData((prevObj) => ({
      ...prevObj,
      [name]: value,
    }));
  };

  const handleUpdateUser = async () => {
    try {
      let query = {
        userId: formData.id,
      };
      let data = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        userName: formData.userName,
        contact: formData.contact,
        title:formData.title.value,
      };
      let response = await updateUser({ query, data }).unwrap();
      dispatch(
        addNotification({
          type: "success",
          message: "Successfully Updated",
        })
      );
      handleEnableEdit();
    } catch (error) {
      if (error?.status == 401 || error?.status == 403) {
        sessionStorage.removeItem("access_token");
        navigate("/login");
        dispatch(
          addNotification({
            type: "error",
            message: "Token is invalid or expired",
          })
        );
      } else {
        dispatch(
          addNotification({ type: "error", message: "Unable to fetch!" })
        );
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { isValid, errors } = validateRegForm(formData);
    setFormError(errors);

    if (isValid) {
      setFormError({});
      handleUpdateUser();
    }
  };

  // Handle to enable the edit mode
  const handleEnableEdit = () => {
    setEditMode(!editMode);
  };

  if (userDetailLoading?.isLoading) {
    return <Loader />;
  } else {
    return (
      <div className="xl:px-[35px] xl:py-12 lg:px-5 lg:py-7 p-4">
        <div className="p-5 bg-white rounded-xl">
          <div className="flex justify-between mb-7 items-center">
            <Button
              clickHandler={() => {
                navigate(-1);
              }}
              classname="!w-auto"
            >
              Go Back
            </Button>
            <div className="flex items-center gap-5">
              <Button clickHandler={handleEnableEdit} classname="!w-auto">
                {editMode ? (
                  "Cancel"
                ) : (
                  <span className="flex items-center gap-2">
                    <EditIcon />
                    Edit
                  </span>
                )}
              </Button>
              {editMode && (
                <Button clickHandler={handleSubmit} classname="!w-auto">
                  Update
                </Button>
              )}
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex gap-4 flex-wrap">
              <div className="lg:w-[calc(33.33%-12px)] sm:w-[calc(50%-16px)] w-full">
                {editMode ? (
                  <div
                    className={`input-wrapper flex flex-col w-full ${
                      formError?.title ? "error" : ""
                    } `}
                  >
                    <label className="input-label mb-2.5">Title</label>
                    <DropDown
                      label="Title"
                      options={NameTitle}
                      inputWidth={"100%"}
                      isMultiSelected={false}
                      placeholder="Select title"
                      onSelectChange={(value) =>
                        handleTitleChange("title", value)
                      }
                      selectedValue={formData?.title}
                    />
                    {formError?.title !== null && (
                      <p className="error-text">
                        <span className="icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M8 12.173a0.591 0.591 0 1 0 0 -1.182 0.591 0.591 0 0 0 0 1.182Zm0 -2.123a0.444 0.444 0 0 1 -0.445 -0.445v-5.333a0.444 0.444 0 1 1 0.889 0v5.333a0.444 0.444 0 0 1 -0.444 0.444Z"
                              fill="red"
                            />
                            <path
                              d="M8 15.111a7.112 7.112 0 1 1 0 -14.222 7.112 7.112 0 0 1 0 14.222Zm0 -13.333a6.222 6.222 0 1 0 0 12.445 6.222 6.222 0 0 0 0 -12.445Z"
                              fill="red"
                            />
                          </svg>
                        </span>
                        {formError?.title}
                      </p>
                    )}
                  </div>
                ) : (
                  <Input
                    label="Title"
                    type="text"
                    className="normal-input  w-full"
                    changeEvent={handleInputChange}
                    name="title"
                    isErrorMsg={formError?.title}
                    isReadOnly={!editMode}
                    attributes={{ value: formData?.title }}
                  />
                )}
              </div>
              <div className="lg:w-[calc(33.33%-12px)] sm:w-[calc(50%-16px)] w-full">
                <Input
                  label="First Name"
                  placeholder={"Enter First Name"}
                  type="text"
                  className="normal-input  w-full"
                  changeEvent={handleInputChange}
                  name="firstName"
                  isErrorMsg={formError?.firstName}
                  isReadOnly={!editMode}
                  attributes={{ value: formData?.firstName }}
                />
              </div>
              <div className="lg:w-[calc(33.33%-12px)] sm:w-[calc(50%-16px)] w-full">
                <Input
                  label="Last Name"
                  placeholder={"Enter Last Name"}
                  type="text"
                  className="normal-input  w-full"
                  changeEvent={handleInputChange}
                  name="lastName"
                  isErrorMsg={formError?.lastName}
                  isReadOnly={!editMode}
                  attributes={{ value: formData?.lastName }}
                />
              </div>
              <div className="lg:w-[calc(33.33%-12px)] sm:w-[calc(50%-16px)] w-full">
                <Input
                  label="Username"
                  placeholder={"Enter Username"}
                  type="text"
                  className="normal-input  w-full"
                  changeEvent={handleInputChange}
                  name="userName"
                  isErrorMsg={formError?.userName}
                  isReadOnly={!editMode}
                  attributes={{ value: formData?.userName }}
                />
              </div>
              <div className="lg:w-[calc(33.33%-12px)] sm:w-[calc(50%-16px)] w-full">
                {editMode ? (
                  <Input
                    label="contact"
                    type="tel"
                    className="normal-input  w-full"
                    changeEvent={handleInputChange}
                    name="contact"
                    isErrorMsg={formError?.contact}
                    attributes={{
                      value: formData?.contact,
                    }}
                  />
                ) : (
                  <Input
                    label="contact"
                    type="text"
                    className="normal-input  w-full"
                    changeEvent={handleInputChange}
                    name="contact"
                    isErrorMsg={formError?.contact}
                    isReadOnly={!editMode}
                    attributes={{
                      value: formData?.contact,
                    }}
                  />
                )}
              </div>
              <div className="lg:w-[calc(33.33%-12px)] sm:w-[calc(50%-16px)] w-full">
                <Input
                  label="Email"
                  placeholder={"Enter Email"}
                  type="text"
                  className="normal-input  w-full"
                  changeEvent={handleInputChange}
                  name="email"
                  isErrorMsg={formError?.email}
                  isReadOnly={true}
                  attributes={{ value: formData?.email }}
                />
              </div>
              <div className="lg:w-[calc(33.33%-12px)] sm:w-[calc(50%-16px)] w-full">
                <Input
                  label="User Type"
                  type="text"
                  className="normal-input  w-full capitalize"
                  changeEvent={handleInputChange}
                  name="userType"
                  isErrorMsg={formError?.userType}
                  isReadOnly={true}
                  attributes={{ value: formData?.userType.replace("_", " ") }}
                />
              </div>
              {formData?.userType === "health_worker" && (
                <div className="lg:w-[calc(33.33%-12px)] sm:w-[calc(50%-16px)] w-full">
                  <Input
                    label="Health Worker Type"
                    type="text"
                    className="normal-input  w-full uppercase"
                    changeEvent={handleInputChange}
                    name="healthWorkerType"
                    isErrorMsg={formError?.healthWorkerType}
                    isReadOnly={true}
                    attributes={{ value: formData?.healthWorkerType }}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export default ViewUser;
