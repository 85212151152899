import React, { useState } from "react";
import {
  PasswordEyeIcon,
  PasswordEyeSlashIcon,
} from "../../../../assets/Icons";

const TextInput = (props) => {
  const [visible, setVisible] = useState(false);
  const {
    type,
    className,
    label,
    placeholder,
    name,
    isFocus = false,
    isErrorMsg = null,
    changeEvent,
    attributes,
    isReadOnly = false,
    isDisabled = false,
  } = props;

  const checkEvent = (e) => {
    const { name, value } = e.target;
    if (changeEvent) {
      changeEvent(e);
    }
  };
  return (
    <div className={`input-wrapper w-full flex flex-col ${isErrorMsg ? "error" : ""}`}>
      {label ? (
        <label
          className={
            isReadOnly ? "input-label-read-only" : "input-label pb-2.5"
          }
        >
          {label}
        </label>
      ) : null}
      {type === "password" ? (
        <div className="input-default-password relative">
          <input
            type={visible ? "text" : type}
            placeholder={placeholder}
            className={` ${
              className ? className : "w-full border border-black"
            } input-tag w-full !pr-8`}
            name={name}
            onChange={checkEvent}
            readOnly={isReadOnly}
            autoFocus={isFocus}
            disabled={isDisabled}
          />
          <span
            className="eye-icon absolute right-2 top-1/2 -translate-y-1/2"
            onClick={() => setVisible((prev) => !prev)}
          >
            {visible ? <PasswordEyeSlashIcon /> : <PasswordEyeIcon />}
          </span>
        </div>
      ) : (
        <input
          type={type}
          placeholder={placeholder}
          className={` ${
            className ? className : "w-full border border-black"
          } input-tag `}
          name={name}
          onChange={checkEvent}
          readOnly={isReadOnly}
          autoFocus={isFocus}
          disabled={isDisabled}
          {...attributes}
        />
      )}

      {isErrorMsg !== null && (
        <p className="error-text">
          <span className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={16}
              height={16}
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8 12.173a0.591 0.591 0 1 0 0 -1.182 0.591 0.591 0 0 0 0 1.182Zm0 -2.123a0.444 0.444 0 0 1 -0.445 -0.445v-5.333a0.444 0.444 0 1 1 0.889 0v5.333a0.444 0.444 0 0 1 -0.444 0.444Z"
                fill="red"
              />
              <path
                d="M8 15.111a7.112 7.112 0 1 1 0 -14.222 7.112 7.112 0 0 1 0 14.222Zm0 -13.333a6.222 6.222 0 1 0 0 12.445 6.222 6.222 0 0 0 0 -12.445Z"
                fill="red"
              />
            </svg>
          </span>
          {isErrorMsg}
        </p>
      )}
    </div>
  );
};

export default TextInput;
