import React from "react";
import DatePickerApp from "./DatePickerApp";
import RadioInput from "./radioInput/RadioInput";
import TextInput from "./textInput/TextInput";
import PhoneInput from "./PhoneInput";

const Input = (props) => {
  const { type = "text" } = props;

  const renderInput = (type) => {
    switch (type) {
      case "text":
        return <TextInput {...props} />;
      case "number":
        return <TextInput {...props} />;
      case "email":
        return <TextInput {...props} />;
      case "tel":
        return <PhoneInput {...props} />;
      case "password":
        return <TextInput {...props} />;
      case "radio":
        return <RadioInput {...props} />;
      case "datePicker":
        return <DatePickerApp {...props} />;
      case "monthPicker":
        return <DatePickerApp {...props} />;
      case "yearPicker":
        return <DatePickerApp {...props} />;
      default:
        return null;
    }
  };

  return renderInput(type);
};

export default Input;
