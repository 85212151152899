import React from "react";
import '../../../scss/common/index.scss';

const Loader = () => {
  return (
    <div className="fixed lg:max-w-[calc(100%-320px)] w-full h-screen bg-[rgba(0,0,0,0.0.2] flex justify-center items-center">
      <span className="loader"></span>
    </div>
  );
};

export default Loader;
